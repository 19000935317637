"use client";

import {SButton} from "@/components/atoms/button";
import {ErrorMessage, SuccessMessage} from "@/components/atoms/typographies";
import {useSendFeedbackMutation} from "@/lib/client/slices";
import {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Icon} from "@/components/atoms/icons";
import {MdOutlineFeedback} from "react-icons/md";


export const FeedbackPopup = () => {
  const [open, setOpen] = useState(false);
  const {register, handleSubmit,} = useForm()
  const [sendFeedback, status] = useSendFeedbackMutation()
  const onSubmit: SubmitHandler<any> = (data) => {
    sendFeedback({
      description: data.message,
      name: data.name,
      medium: 'website',
      source: 'feedback_popup',
      term: data.term,
      type: 'feeback'
    })
  }

  const Form = () => (
    <form onSubmit={handleSubmit(onSubmit)}
          className="mx-auto mt-8 flex w-full max-w-xl flex-col rounded-t-lg border bg-white p-4 ">
      <h2 className="title-font mb-1 text-lg font-medium text-gray-900">
        Donnez votre avis
      </h2>
      <p className="mb-3 leading-relaxed text-gray-600">
        Si vous rencontrez des problèmes, si vous avez des idées d'amélioration
        ou si vous aimez notre site internet, faites-le nous savoir !
      </p>
      <div className="mb-2">
        <label className="text-sm leading-7 text-gray-600">Votre nom (facultatif)</label>
        <input
          {...register("name")}
          type="text"
          id="name"
          name="name"
          disabled={status.isLoading}
          className="w-full rounded border border-gray-300 bg-white px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
        />
      </div>
      <div className="mb-2">
        <label className="text-sm leading-7 text-gray-600">Une catégorie</label>
        <select
          {...register("term", {required: true})}
          id="term"
          name="term"
          disabled={status.isLoading}
          // placeholder="Selectionnez une option"
          className="w-full rounded border border-gray-300 bg-white px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
        >
          <option value="idea_for_website">J'ai une idée à vous proposer</option>
          <option value="issue_with_website">J'ai un problème avec le site</option>
          <option value="only_message">Je voulais juste laisser un message</option>
        </select>
      </div>
      <div className="mb-2">
        <label className="text-sm leading-7 text-gray-600">Votre message</label>
        <textarea
          {...register("message", {required: true})}
          id="message"
          name="message"
          disabled={status.isLoading}
          className="h-32 w-full resize-none rounded border border-gray-300 bg-white px-3 py-1 text-base leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
        ></textarea>
      </div>
      {status.isError &&
          <ErrorMessage>Oops, une erreur est survenue... veuillez réessayer un peu plus tard</ErrorMessage>}
      {status.isSuccess && <SuccessMessage>Un grand merci ! vous pouvez fermer cette fenêtre</SuccessMessage>}
      <SButton type="submit"
               className="cursor-pointer rounded border-0 bg-blue-500 px-6 py-2 text-lg text-white hover:bg-blue-600 focus:outline-none"
               loading={status.isLoading}>
        Envoyer un commentaire
      </SButton>
      <p className="mt-3 text-xs text-gray-500">
        Vos retours sont indispensables pour faire progresser ce site internet.
      </p>
    </form>
  );

  const Button = () => {
    if (open) {
      return <div onClick={() => setOpen(false)}
                  className="flex items-center justify-center rounded-b-xl bg-gray-500 px-4 py-2 font-medium text-white shadow-lg transition duration-300 ease-in-out hover:bg-gray-600 cursor-pointer">Fermer</div>;
    }

    return (
      <div
        onClick={() => setOpen(true)}
        className="flex items-center justify-center items-center rounded-md bg-teal-500 px-4 py-2 font-medium text-white shadow-lg transition duration-300 ease-in-out hover:bg-teal-600 cursor-pointer"
      >
        <Icon icon={MdOutlineFeedback} value={<span className="hidden md:block">Donnez votre avis</span>}/>
      </div>
    );
  };

  return (
    <div className="fixed bottom-0 right-0 md:bottom-6 md:right-6 md:h-auto z-50">
      {open && <Form/>}
      <Button/>
    </div>
  );
};
