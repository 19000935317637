import {apiSlice} from "./api";


const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => ({
    getUnreadMessages: builder.query<{ unread: number }, void>({
      query: () => `/messages/unread`,
    }),
    createConversation: builder.mutation<void, {
      origin_type: string,
      origin_id: string,
      user_id: string,
      message: string
    }>({
      query: (data) => ({
        url: `/messages/conversations`, method: 'post', body: data
      }),
    }),
    replyConversation: builder.mutation<void, { id: string, message: string }>({
      query: ({id, ...data}) => ({
        url: `/messages/conversations/${id}/reply`, method: 'post', body: data
      }),
    }),
    archiveConversation: builder.mutation<void, { id: string }>({
      query: ({id, ...data}) => ({
        url: `/messages/conversations/${id}/archive`, method: 'post', body: data
      }),
    }),
    createComment: builder.mutation<void, { id: string }>({
      query: (data) => ({
        url: `/messages/comments`, method: 'post', body: data
      }),
    }),

  })), overrideExisting: false
})


export const {
  useGetUnreadMessagesQuery,
  useCreateConversationMutation,
  useReplyConversationMutation,
  useArchiveConversationMutation,
  useCreateCommentMutation
} = extendedApi
