import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit'
import appSlice from "@/lib/client/slices/slice";
import {apiSlice} from "@/lib/client/slices/api";

export function makeStore() {
  return configureStore({
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
      app: appSlice
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(apiSlice.middleware)
  })
}

const store = makeStore()

// TODO DUPLICATE
export type AppState = ReturnType<typeof store.getState>
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>

export default store


