import * as React from "react";
import {DetailedHTMLProps} from "react";
import classNames from "classnames";
//
// export type IconTypes =
//   | 'account'
//   | 'account-confirmed'
//   | 'agenda'
//   | 'album'
//   | 'community'
//   | 'facebook'
//   | 'handshake'
//   | 'help'
//   | 'instagram'
//   | 'lodging'
//   | 'magazine'
//   | 'news'
//   | 'pin'
//   | 'plan'
//   | 'solidarity'
//   | string
//
//
// interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
//   icon: IconTypes,
//   size?: 'sm' | 'md' | 'lg'
// }
//
// // depreacted
// export const Icons = ({icon, size, ...rest}: Props) => {
//   // @ts-ignore
//   return <StyledIcons $icon={icon} $size={size ?? 'md'}
//                       className={classNames((rest.onClick ? "button" : ''))} {...rest} />
// }
//
//
// const StyledIcons = styled.i<{ $icon: string, $size: string }>`
//   display: inline-block;
//   vertical-align: middle;
//   margin: ${props => props.theme.padding}px;
//
//   &.button {
//     &:hover {
//       cursor: pointer;
//     }
//   }
//
//   ${props => props.$size == 'sm' && css`
//     width: 24px;
//     height: 24px;
//   `}
//
//   ${props => props.$size == 'md' && css`
//     width: 30px;
//     height: 30px;
//   `}
//
//   ${props => props.$size == 'lg' && css`
//     width: 96px;
//     height: 96px;
//   `}
//
//   background: url("/icons/${props => props.$icon}.png") center / contain;
// `
//

interface Props extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon?: any
  image?: string
  value?: any
}

export const Icon = ({children, className, icon, image, value, ...rest}: Props) => {
  const Element = icon
  const s = image ? {background: `url(/icons/${image}.png) center / contain`} : {}
  return <span className={value && "flex items-center px-2"}>
          <div className={classNames("pr-1 inline-block flex items-center", className)} {...rest}
               style={s}>
          {(Element && <Element/>) || children}
        </div>
    {value}
    </span>
}