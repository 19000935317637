import classNames from "classnames";

export const Title = (props: any) => <h1 {...props} className="text-black text-3xl"/>
export const TitleLabel = (props: any) => <p {...props} className="text-black text-base"/>
export const SubTitle = (props: any) => <h2 {...props} className="text-black pb-2 pt-2 text-2xl"/>
export const SmallTitle = (props: any) => <h3 {...props} className="pl-2 text-xl font-medium"/>
export const SSmallText = (props: any) => <p {...props} className="p-2 rounded-lg text-black pb-2 pt-2 text-sm"/>

// message cards
export const NeutralMessage = (props: any) => <p {...props}
                                                 className={classNames(" border-neutral-400 p-2 mb-2 rounded text-black pb-2 pt-2 text-sm  m-2", props.className)}/>
export const InfoMessage = (props: any) => <p {...props}
                                              className={classNames("border-l-8 border-t border-b border-r border-blue-400 p-2 mb-2 rounded text-black pb-2 pt-2 text-sm  m-2 ", props.className)}/>
export const WarnMessage = (props: any) => <p {...props}
                                              className={classNames("border-l-8 border-t border-b border-r border-orange-400 p-2 mb-2 rounded text-black pb-2 pt-2 text-sm  m-2", props.className)}/>
export const SuccessMessage = (props: any) => <p {...props}
                                                 className={classNames("border-l-8 border-t border-b border-r border-emerald-600 p-2 mb-2 rounded text-black pb-2 pt-2 text-sm  m-2", props.className)}/>
export const ErrorMessage = (props: any) => <p {...props}
                                               className={classNames("border-l-8 border-t border-b border-r border-red-400 p-2 mb-2 rounded text-black pb-2 pt-2 text-sm  m-2", props.className)}/>



