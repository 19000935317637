import {apiSlice} from "./api";
import {serialize} from "../../tools/strings";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder => ({
    getGeoCountry: builder.mutation<any, { code: string }>({
      query: (data) => `/locations/world/countries/${data.code}?geo=true`,
    }), getGeoContinent: builder.mutation<any, { code: string }>({
      query: (data) => `/locations/world/continents/${data.code}?geo=true`,
    }),
    getGeoCity: builder.mutation<any, { code: string }>({
      query: (data) => `/locations/world/cities/${data.code}?geo=true`,
    }),
    getCities: builder.mutation<any, { [key: string]: any }>({
      query: (data) => `/locations/world/cities/?${serialize(data)}`,
    })
  })), overrideExisting: false
})


export const {
  useGetGeoCountryMutation, useGetGeoContinentMutation, useGetGeoCityMutation,
  useGetCitiesMutation

} = extendedApi
