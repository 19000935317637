import(/* webpackMode: "eager", webpackExports: ["FeedbackPopup"] */ "/app/app/components/organisms/feedbacks/feedback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/app/app/components/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/app/app/components/web-vitals.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/app/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/ReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptchaContext","ReCaptchaProvider","useReCaptchaContext"] */ "/app/node_modules/next-recaptcha-v3/lib/ReCaptchaProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/useReCaptcha.js");
;
import(/* webpackMode: "eager", webpackExports: ["withReCaptcha"] */ "/app/node_modules/next-recaptcha-v3/lib/withReCaptcha.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/primeicons/primeicons.css");
;
import(/* webpackMode: "eager", webpackExports: ["PrimeReactProvider"] */ "/app/node_modules/primereact/api/api.esm.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/primereact/passthrough/tailwind/index.esm.js");
