'use client';

import {Provider} from 'react-redux';
import store from '@/lib/client/store';

export const StoreProvider = ({children}: { children: React.ReactNode }): any => {
  return <Provider store={store}>
    {children}
  </Provider>
}


