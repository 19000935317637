'use client'

import {Button} from "primereact/button";
import {FileUpload} from "primereact/fileupload";
import Link from "next/link";
import classNames from "classnames";
import {Icon} from "@/components/atoms/icons";
import {IoMdArrowDropright} from "react-icons/io";
import {useRouter} from "next/navigation";

export type CssVariant = 'alternative' | ' danger' | 'success' | 'highlight' | 'contrast'| string | undefined;
export type CssSize = 'sm' | ' lg' | string | undefined;

export const SCenter = (props: any) => <div className={'flex justify-center items-center m-2 p-2'}>{props.children}</div>

export const SButton = (props: {variant ? : CssVariant, size?: CssSize} & any) => <Button {...props}
                                                                                            ptOptions={{mergeSections: false}}
                                                                                            pt={{
                                                                                              root: classNames("rounded p-1 pl-3 pr-3 m-1 text-white flex items-center border bg-primary border-primary hover:bg-primary-dark",
                                                                                                {'bg-gray-500 border-gray-500 hover:border-gray-600 hover:bg-gray-600': props.variant === 'alternative'},
                                                                                                {'bg-red-500 border-red-500 hover:border-red-600 hover:bg-red-600': props.variant === 'danger'},
                                                                                                {'bg-green-500 border-green-500 hover:border-green-600 hover:bg-green-600': props.variant === 'success'},
                                                                                                {'bg-secondary border-secondary hover:border-secondary hover:bg-secondary': props.variant === 'highlight'},
                                                                                                {'text-sm': props.size === 'sm'},
                                                                                                {'text-lg': props.size === 'lg'},
                                                                                                props.className),
                                                                                            }}/>
  export const SLinkButton = (props: {variant ? : CssVariant} & any) => <Link {...props}
                                                                              className={classNames("rounded p-1 pl-3 pr-3 m-1 text-white flex items-center border bg-primary border-primary  hover:bg-primary-dark",
                                                                                {'bg-gray-500 border-gray-500 hover:border-gray-600 hover:bg-gray-600': props.variant === 'alternative'},
                                                                                {'bg-red-500 border-red-500 hover:border-red-600 hover:bg-red-600': props.variant === 'danger'},
                                                                                {'bg-green-500 border-green-500 hover:border-green-600 hover:bg-green-600': props.variant === 'success'},
                                                                                {'bg-secondary border-secondary hover:border-secondary hover:bg-secondary': props.variant === 'highlight'},
                                                                                {'bg-gray-700 border-gray-300 hover:border-gray-300 hover:bg-gray-500': props.variant === 'contrast'},
                                                                                {'text-sm': props.size === 'sm'},
                                                                                {'text-lg': props.size === 'lg'},
                                                                                props.className)}/>

  export const SCenterLinkButton = (props: any) => <div className={'flex justify-center items-center m-2 p-2'}>
  <SLinkButton href={props.href} title={props.children}>
    <div><Icon icon={IoMdArrowDropright}/></div>
    <div>{props.children}</div>,
  </SLinkButton>
</div>

  export const StyledFileUpload = (props: any) => <FileUpload {...props}
                                                              pt={{
                                                                basicButton: {
                                                                  className: "cursor-pointer rounded p-2 bg-primary border-primary hover:bg-primary-dark block text-center"
                                                                }
                                                              }}
                                                              chooseOptions={{
                                                                icon: 'pi pi-fw pi-images',
                                                                iconOnly: false,
                                                                style: {padding: 8},
                                                                className: 'p-2'
                                                              }}
                                                              className={classNames("", props.className)}/>


  export const ForceRefresh = () => {
  const router = useRouter();
  // Force refresh the page
  const handleReload = () => {
  router.refresh();
};
  return (
  <SButton onClick={handleReload} variant="alternative">Actualiser</SButton>
)
;
};