import {WebsiteConfiguration} from '@/lib/types'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1'
  }),
  endpoints: (builder) => ({
    getConfiguration: builder.query<WebsiteConfiguration, void>({
      query: () => `/information`,
    }),
    get: builder.query<any, string>({
      query: (q) => q,
    }),
    getAsync: builder.mutation<void, string>({
      query: (q) => q,
    }),
    subscribeNewsletter: builder.mutation<void, { email: string }>({
      query: ({email}) => ({
        url: '/newsletters/subscribe',
        method: 'post',
        body: {email}
      })
    }),

  }),
})

export const {
  useGetQuery,
  useGetAsyncMutation,
  useGetConfigurationQuery,
  useSubscribeNewsletterMutation,
} = apiSlice

